
import { renderRichText } from "@storyblok/nuxt-2"
export default {
  props: {
    blok: {
      type: Object,
      default: () => ({}),
    },
  },
  computed: {
    description() {
      return renderRichText(this.blok.description)
    },
  },
}
